import React from "react"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"

import LayoutCourse from "../../../../../layouts/course"

import CourseFooterNext from "../../../../../components/course-footer-next"
import Divider from "../../../../../components/divider"
import Heading from "../../../../../components/heading"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"

import LetterNote from "../../../../../content/kurse/reiseerinnerung/01-suedfrankreich/letter-note"
import LetterSidebar from "../../../../../content/kurse/reiseerinnerung/01-suedfrankreich/letter-sidebar"
import MoodBarometer from "../../../../../content/kurse/haelfte-des-lebens/02-entstehungsgeschichte/mood-barometer"
import AudioPlayer from "../../../../../components/audio-player"

const Page = () => {
  const { setAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/haelfte-des-lebens/02-entstehungsgeschichte/stimmungsbarometer/aufgabe-3" />
      }
    >
      <Seo title="Hölderlin im Stimmungsbarometer" />
      <Stack>
        <Heading as="h2" level={2}>
          Hölderlin im Stimmungsbarometer
        </Heading>
        <LetterNote />
        <LetterSidebar
          before={<AudioPlayer src={`kurse/haelfte-des-lebens/brief-2`} />}
          main={
            <Stack space={6}>
              <p>
                Brief vom 11. Dezember 1800, in dem Hölderlin seiner Schwester
                Rieke seine Beweggründe für die Annahme einer neuen
                Hauslehrerstelle im schweizerischen Hauptwil schildert. Zu
                diesem Zeitpunkt ist Hölderlin 30 Jahre alt. Mitte Januar 1801
                wird er sich zu Fuß auf den Weg in die Schweiz machen.
              </p>
              <Divider size={4} />
              <p>
                Ich gestehe Dir, Theure! daß ich meinen Entschluß, so sehr er
                meinem Herzen widersprach, doch immer mehr mit meinem Herzen zu
                reimen weiß. Ich habe in mir ein so tiefes dringendes Bedürfniß
                nach Ruhe und Stille — mehr als Du mir ansehn kannst, und ansehn
                sollst. Und wenn ich diß in meiner künftigen Lage finde, so
                erhalte ich mein Herz meinen unvergeßlichen Verwandten und
                Freunden nur umso wärmer und treuer. Ich kann den Gedanken nicht
                ertragen, daß auch ich, wie mancher andere, in der kritischen
                Lebenszeit, wo um unser Inneres her, mehr noch als in der
                Jugend, eine betäubende Unruhe sich häuft, daß ich, um
                auszukommen, so kalt und allzunüchtern und verschlossen werden
                soll. Und in der That, ich fühle mich oft, wie Eis, und fühle es
                nothwendig, so lange ich keine stillere Ruhestätte habe, wo
                alles was mich angeht, mich weniger nah, und eben deßwegen
                weniger erschütternd bewegt. Hierinn liegt für mich, und wie ich
                glaube, auch für die Meinigen, der Hauptgrund, der mich, wo
                manches andere auf beiden Seiten gleich war, zu meinem
                Entschlusse bestimmte.
              </p>
            </Stack>
          }
          sidebar={
            <MoodBarometer
              onChange={({ state }) => {
                setAnswer({
                  courseId: "haelfte-des-lebens",
                  chapterId: "02-entstehungsgeschichte",
                  taskId: "stimmungsbarometer-2",
                  answer: state,
                })
              }}
            />
          }
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
